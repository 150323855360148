import React from "react"

import Layout from "../components/layout"

import headerImage from "./../images/main/3.png"

const Services = () => (
  <Layout>
    <div className="services">
      <div
        className="header-image"
        style={{ backgroundImage: `url(${headerImage})` }}
      />
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="twelve columns">
            <h1>Leda Homecare Ltd</h1>
            <h2>Data Protection Privacy Notice for Clients</h2>

            <p>
              <strong>Introduction:</strong>
            </p>

            <p>
              This privacy notice lets you know what happens to any personal
              data that you give to us, or any that we may collect from or about
              you.
            </p>
            <p>
              This privacy notice applies to personal information processed by
              or on behalf of the Organisation.
            </p>
            <p>This Notice explains</p>
            <ul>
              <li>
                Who we are, how we use your information and our Data Protection
                Officer
              </li>
              <li>
                What kinds of personal information about you do we process?
              </li>
              <li>
                What are the legal grounds for our processing of your personal
                information (including when we share it with others)?
              </li>
              <li>What should you do if your personal information changes?</li>
              <li>For how long your personal information is retained by us?</li>
              <li>What are your rights under data protection laws?</li>
            </ul>

            <p>
              The General Data Protection Regulation (GDPR) became law on 24th
              May 2016. This is a single EU-wide regulation on the protection of
              confidential and sensitive information. It enters into force in
              the UK on the 25th May 2018, repealing the Data Protection Act
              (1998).
            </p>

            <p>
              For the purpose of applicable data protection legislation
              (including but not limited to the General Data Protection
              Regulation (Regulation (EU) 2016/679) (the "GDPR"), and the Data
              Protection Act 2018 (currently in Bill format before Parliament)
              the Organisation responsible for your personal data is Leda
              Homecare Ltd
            </p>
            <p>
              This Notice describes how we collect, use and process your
              personal data, and how, in doing so, we comply with our legal
              obligations to you. Your privacy is important to us, and we are
              committed to protecting and safeguarding your data privacy rights
            </p>
            <p>
              <strong>How we use your information and the law.</strong>
            </p>
            <p>
              Leda Homecare Ltd will be what&rsquo;s known as the
              &lsquo;Controller&rsquo; of the personal data you provide to us.
            </p>
            <p>
              We collect basic personal data about you which does not include
              any special types of information or location-based
              information.&nbsp; This does however include name, address,
              contact details such as email and mobile number etc.
            </p>
            <p>
              We will also collect sensitive confidential data known as
              &ldquo;special category personal data&rdquo;, in the form of
              health information, religious belief (if required) ethnicity, and
              sex during the services we provide to you and or linked to your
              healthcare through other health providers or third parties.
            </p>

            <p>
              <strong>Why do we need your information?</strong>
            </p>
            <p>
              The Care Workers who provide you with care maintain records about
              your health, welfare and any treatment or care you have received
              previously. These records help to provide you with the best
              possible care.&nbsp;
            </p>
            <p>
              Our records may be electronic, on paper or a mixture of both, and
              we use a combination of working practices and technology to ensure
              that your information is kept confidential and secure. Records
              which the Organisation hold about you may include the following
              information;&nbsp;
            </p>
            <ul>
              <li>
                Details about you, such as your address, carer, legal
                representative, emergency contact details
              </li>
              <li>
                Any contact we have had with you, telephone calls, reviews, care
                plan meetings.
              </li>
              <li>Notes and reports about your health and wellbeing</li>
              <li>Details about your care package and care provided.</li>
              <li>
                Relevant information from other health professionals, relatives
                or those who care for you
              </li>
            </ul>
            <p>
              To ensure you receive the best possible care, your records are
              used to facilitate the care you receive. Information held about
              you may be used to help protect yourself and to help us manage our
              Organisation. Information may be used within the Organisation for
              Service audits to monitor the quality of the service provided.
            </p>
            <p>
              <strong>How do we lawfully use your data?</strong>
            </p>
            <p>
              We need to know your personal, sensitive and confidential data in
              order to provide you with Care services as a Domiciliary Care
              Organisation, under the General Data Protection Regulation we will
              be lawfully using your information in accordance with: -
            </p>
            <p>
              <em>
                Article 6, e) processing is necessary for the performance of a
                task carried out in the public interest or in the exercise of
                official authority vested in the controller;&rdquo;{" "}
              </em>
            </p>
            <p>
              <em>
                Article 9, (h) processing is necessary for the purposes of
                preventive or occupational medicine, for the assessment of the
                working capacity of the employee, medical diagnosis, the
                provision of health or social care or treatment or the
                management of health or social care systems{" "}
              </em>
            </p>
            <p>
              This Privacy Notice applies to the personal data of our clients
              and the data you have given us about your carers/family members.
            </p>

            <p>
              <strong>
                How do we maintain the confidentiality of your records?&nbsp;{" "}
              </strong>
            </p>
            <p>
              We are committed to protecting your privacy and will only use
              information collected lawfully in accordance with:
            </p>
            <ul>
              <li>Data Protection Act 2018</li>
              <li>The General Data Protection Regulations 2016</li>
              <li>Human Rights Act 1998</li>
              <li>Common Law Duty of Confidentiality</li>
              <li>Health and Social Care Act 2012</li>
              <li>
                NHS Codes of Confidentiality, Information Security and Records
                Management
              </li>
              <li>Information: To Share or Not to Share Review</li>
            </ul>

            <p>
              Every member of staff who works for the organisation has a legal
              obligation to keep information about you confidential.&nbsp;
            </p>
            <p>
              We will only ever use or pass on information about you if others
              involved in your care have a genuine need for it. We will not
              disclose your information to any third party without your
              permission unless there are exceptional circumstances (i.e. life
              or death situations), where the law requires information to be
              passed on and / or in accordance with the information sharing
              principle following Dame Fiona Caldicott&rsquo;s information
              sharing review (Information to share or not to share) where
              &ldquo;The duty to share information can be as important as the
              duty to protect client confidentiality.&rdquo; This means that
              health and social care professionals should have the confidence to
              share information in the best interests of their patients /
              clients within the framework set out by the Caldicott principles.
            </p>
            <p>
              Our Organisation policy is to respect the privacy of our clients,
              their families and our staff and to maintain compliance with the
              General Data Protection Regulations (GDPR) and all UK specific
              Data Protection Requirements. Our policy is to ensure all personal
              data related to our clients will be protected.
            </p>

            <p>
              All employees and sub-contractors engaged by our practice are
              asked to sign a confidentiality agreement. The Organisation will,
              if required, sign a separate confidentiality agreement if the
              client deems it necessary.&nbsp; If a sub-contractor acts as a
              data processor for Leda Homecare Ltd an appropriate contract (art
              24-28) will be established for the processing of your information.
            </p>

            <p>
              In Certain circumstances you may have the right to withdraw your
              consent to the processing of data. Please contact the Data
              Protection Officer in writing if you wish to withdraw your
              consent.&nbsp; In some circumstances we may need to store your
              data after your consent has been withdrawn to comply with a
              legislative requirement.
            </p>
            <p>
              Some of this information will be held centrally and used for
              statistical purposes. Where we do this, we take strict measures to
              ensure that individual clients cannot be identified. Sometimes
              your information may be requested to be used for research purposes
              &ndash; the organisation will always gain your consent before
              releasing the information for this purpose in an identifiable
              format. &nbsp;&nbsp;In some circumstances you can Opt-out of the
              organisation sharing any of your information for research
              purposes.
            </p>
            <p>
              <strong>
                With your consent we would also like to use your information to
              </strong>
            </p>
            <p>
              At any stage where we would like to use your data for anything
              other than the specified purposes and where there is no lawful
              requirement for us to share or process your data, we will ensure
              that you have the ability to consent and opt out prior to any data
              processing taking place.
              <br /> This information is not shared with third parties or used
              for any marketing and you can unsubscribe at any time via phone,
              email or by informing the practice DPO as below.
            </p>
            <p>
              <strong>
                Where do we store your information Electronically?
              </strong>
            </p>
            <p>
              All the personal data we process is processed by our staff in the
              UK however for the purposes of IT hosting and maintenance this
              information may be located on servers within the European Union.
            </p>
            <p>
              No 3rd parties have access to your personal data unless the law
              allows them to do so and appropriate safeguards have been put in
              place. &nbsp;We have a Data Protection regime in place to oversee
              the effective and secure processing of your personal and or
              special category (sensitive, confidential) data.
            </p>
            <p>
              <strong>Who are our partner organisations? </strong>
            </p>
            <p>
              We may also have to share your information, subject to strict
              agreements on how it will be used, with the following
              organisations;
            </p>
            <ul>
              <li>NHS Trusts / Foundation Trusts</li>
              <li>GP&rsquo;s</li>
              <li>NHS Commissioning Support Units</li>
              <li>
                Independent Contractors such as dentists, opticians, pharmacists
              </li>
              <li>Private Sector Providers</li>
              <li>Voluntary Sector Providers</li>
              <li>Ambulance Trusts</li>
              <li>Clinical Commissioning Groups</li>
              <li>Social Care Services</li>
              <li>NHS England (NHSE) and NHS Digital (NHSD)</li>
              <li>Local Authorities</li>
              <li>Education Services</li>
              <li>Fire and Rescue Services</li>
              <li>Police &amp; Judicial Services</li>
              <li>Voluntary Sector Providers</li>
              <li>Private Sector Providers</li>
              <li>
                Other &lsquo;data processors&rsquo; which you will be informed
                of
              </li>
            </ul>

            <p>
              You will be informed who your data will be shared with and in some
              cases asked for consent for this to happen when this is required.
            </p>

            <p>
              We may also use external companies to process personal
              information, such as for archiving purposes. These companies are
              bound by contractual agreements to ensure information is kept
              confidential and secure.&nbsp; All employees and sub-contractors
              engaged by our organisation are asked to sign a confidentiality
              agreement. If a sub-contractor acts as a data processor for Leda
              Homecare Ltd an appropriate contract (art 24-28) will be
              established for the processing of your information.
            </p>

            <p>
              <strong>How long will we store your information?</strong>
            </p>
            <p>
              We are required under UK law to keep your information and data for
              the full retention periods as specified by the NHS Records
              management code of practice for health and social care and
              national archives requirements.
              <br /> <br /> More information on records retention can be found
              online at
              (https://digital.nhs.uk/article/1202/Records-Management-Code-of-Practice-for-Health-and-Social-Care-2016)
            </p>
            <p>
              <strong>
                How can you access, amend move the personal data that you have
                given to us?
              </strong>
            </p>
            <p>
              Even if we already hold your personal data, you still have various
              rights in relation to it. To get in touch about these, please
              contact us. We will seek to deal with your request without undue
              delay, and in any event in accordance with the requirements of any
              applicable laws. Please note that we may keep a record of your
              communications to help us resolve any issues which you raise.
            </p>
            <p>
              Right to object: If we are using your data because we deem it
              necessary for our legitimate interests to do so, and you do not
              agree, you have the right to object. We will respond to your
              request within 30 days (although we may be allowed to extend this
              period in certain cases). Generally, we will only disagree with
              you if certain limited conditions apply.
            </p>
            <p>
              Right to withdraw consent: Where we have obtained your consent to
              process your personal data for certain activities (for example for
              a research project), or consent to market to you, you may withdraw
              your consent at any time.
            </p>
            <p>
              Right to erasure: In certain situations (for example, where we
              have processed your data unlawfully), you have the right to
              request us to "erase" your personal data. We will respond to your
              request within 30 days (although we may be allowed to extend this
              period in certain cases) and will only disagree with you if
              certain limited conditions apply. If we do agree to your request,
              we will Delete your data but will generally assume that you would
              prefer us to keep a note of your name on our register of
              individuals who would prefer not to be contacted. That way, we
              will minimise the chances of you being contacted in the future
              where your data are collected in unconnected circumstances. If you
              would prefer us not to do this, you are free to say so.
            </p>
            <p>
              Right of data portability: If you wish, you have the right to
              transfer your data from us to another data controller. We will
              help with this with a data transfer and transfer of your hard copy
              notes
            </p>

            <p>
              <strong>Access to your personal information&nbsp; </strong>
            </p>
            <p>
              Data Subject Access Requests (DSAR): You have a right under the
              Data Protection legislation to request access to view or to obtain
              copies of what information the organisation holds about you and to
              have it amended should it be inaccurate. To request this, you need
              to do the following:
            </p>
            <ul>
              <li>Your request should be made to the organisation</li>
              <li>
                There is no charge to have a copy of the information held about
                you
              </li>
              <li>We are required to respond to you within one month</li>
              <li>
                You will need to give adequate information (for example full
                name, address, date of birth, and details of your request) so
                that your identity can be verified, and your records located
                information we hold about you at any time.
              </li>
            </ul>
            <p>
              <strong>
                What should you do if your personal information changes?
              </strong>
            </p>
            <p>
              You should tell us so that we can update our records please
              contact the Office Manager as soon as any of your details change,
              this is especially important for changes of address or contact
              details (such as your mobile phone number), the organisation will
              from time to time ask you to confirm that the information we
              currently hold is accurate and up-to-date.
            </p>
            <p>
              <strong>Objections / Complaints </strong>
            </p>
            <p>
              Should you have any concerns about how your information is managed
              at our organisation, please contact the Registered Manager or the
              Data Protection Officer as above. If you are still unhappy
              following a review by the Organisation, you have a right to lodge
              a complaint with a supervisory authority: You have a right to
              complain to the UK supervisory Authority as below.
            </p>

            <p>Information Commissioner:</p>
            <p>
              Wycliffe house<br></br>Water Lane<br></br>Wilmslow<br></br>
              Cheshire<br></br>SK9 5AF
            </p>
            <p>Tel: 01625 545745</p>
            <p>
              <a href="https://ico.org.uk/">https://ico.org.uk/</a>
            </p>

            <p>
              If you are happy for your data to be extracted and used for the
              purposes described in this privacy notice, then you do not need to
              do anything.&nbsp; If you have any concerns about how your data is
              shared, then please contact the Organisations&rsquo; Data
              Protection Officer. &nbsp;
            </p>
            <p>
              If you would like to know more about your rights in respect of the
              personal data we hold about you, please contact the Data
              Protection Officer as below.
            </p>

            <p>
              <strong>Data Protection Officer:</strong>
            </p>

            <p>
              The Organisation Data Protection Officer is Verna Roe &ndash;
              Office Manager. Any queries in regard to Data Protection issues
              should be addressed to her at: -
            </p>

            <p>Email: &nbsp; verna.roe@ledahomecare.co.uk</p>
            <p>
              Postal: Office G1<br></br>Carriage Court<br></br> Welbeck<br></br>{" "}
              Worksop<br></br> S80 3LR
            </p>
            <p>
              <strong>Changes:</strong>
            </p>
            <p>
              It is important to point out that we may amend this Privacy Notice
              from time to time.&nbsp; If you are dissatisfied with any aspect
              of our Privacy Notice, please contact the Organisation Data
              Protection Officer.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Services
